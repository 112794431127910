/* Register Page */

.container {
  min-height: 100vh;
  color: black;
  background-color: white;
  font-size: 16px;
  line-height: 1.3;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 80px;
}

.registration-form-header {
  text-align: left;

  max-width: 700px;
  width: 100%;
  margin: 60px 0 0;
  padding: 20px;
}

.registration-form-header h2 {
  font-size: 30px;
  margin-bottom: 10px;
}

.registration-form {
  width: 100%;
  max-width: 700px;

  padding: 20px;
  margin: 0 0 40px 0;
}

.btn-row,
.form-row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
}

.btn-row {
  margin-top: 40px;
}

.registration-form-input {
  background-color: #f1f2f6;
  border: none;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
  transition: 100ms linear;
}

.registration-form-input:hover {
  box-shadow: 0px 0px 0px 4px rgba(104, 109, 224, 0.2);
}

.registration-form-input:focus {
  outline: none;
  box-shadow: 0px 0px 0px 4px rgba(104, 109, 224, 0.2);
  border: 1px solid rgba(104, 109, 224);
}

.form-row label {
  font-weight: 600;
  margin-bottom: 8px;
}

.platform-btn {
  display: flex;
  flex-direction: row;
}

.ios-btn,
.android-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  border: 2px solid rgba(104, 109, 224, 0.2);
  border-radius: 6px;
  background-color: white;
  box-shadow: none;
  color: rgba(104, 109, 224, 0.6);
  font-size: 16px;
  padding: 20px;
  transition: 100ms linear;
  cursor: pointer;
}

.ios-btn:active,
.android-btn:active {
  box-shadow: 0px 0px 0px 4px rgba(104, 109, 224, 0.2);
  transform: translateY(2px);
}

.ios-btn {
  margin-right: 10px;
}

.ios-btn:hover,
.android-btn:hover {
  border: 2px solid rgba(104, 109, 224, 0.8);
}

.ios-btn img,
.android-btn img {
  width: 50px;
  opacity: 0.3;
  padding-bottom: 6px;
  transition: 100ms linear;
}

.ios-btn span,
.android-btn span {
  transition: 100ms linear;
}

.ios-btn:hover > img,
.android-btn:hover > img {
  opacity: 0.8;
}

.ios-btn:hover > span,
.android-btn:hover > span {
  color: rgba(104, 109, 224, 1);
}

.btn-selected {
  border: 2px solid rgba(104, 109, 224, 0.8);
  box-shadow: 0px 0px 0px 4px rgba(104, 109, 224, 0.2);
}

.btn-selected > span,
.btn-selected > img {
  color: rgba(104, 109, 224, 1);
  opacity: 0.8;
}

.submit-btn {
  cursor: pointer;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 6px;
  /* background-color: #5c6bc0; */
  background-image: linear-gradient(to right, #283593, #5c6bc0);
  color: white;
  font-weight: 600;
  font-size: 14px;
  transition: 100ms linear;
  outline: none;
}

.submit-btn:disabled {
  background-image: linear-gradient(to right, #9fa8da, #9fa8da);
}

.disabled-btn {
  background-color: #5359dc;
  transform: none;
}

.submit-btn:active {
  transform: translateY(2px);
}

@keyframes wobble {
  10%,
  90% {
    transform: translateX(-1px);
  }

  20%,
  80% {
    transform: translateX(2px);
  }

  30%,
  50%,
  70% {
    transform: translateX(-3px);
  }

  40%,
  60% {
    transform: translateX(3px);
  }
}

.toggle-left {
  animation: wobble 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translateX(0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.toggle-right {
  animation: wobble 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-direction: reverse;
  transform: translateX(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
