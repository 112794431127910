.rw-widget-picker {
    height: 38px !important;
}

.rw-widget-container {
    background-color: #f1f2f6 !important;
    border: none !important;
    border-radius: 6px !important;
    padding: 7px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    transition: 100ms linear !important;
}

.rw-input {
    background-color: transparent !important;
}

.rw-widget-input {
    box-shadow: none !important;
}

.rw-widget-container:hover {
    box-shadow: 0px 0px 0px 4px rgba(104,109,224,0.2);
}

.rw-state-focus > .rw-widget-container {
    outline: none !important;
    box-shadow: 0px 0px 0px 4px rgba(104,109,224,0.2) !important;
    border: 1px solid rgba(104,109,224) !important;
}

.rbc-event {
    background-color: #0061f2 !important;
}

.rbc-month-row, .rbc-day-bg {
    cursor: pointer;
}